import './App.css';
import Footer from './Footer';
import MainButton from './MainButton';
import FloatingImage from './FloatingImage';
import React, { useState } from 'react';
import Logo from './Logo';
import ImageBlock from './ImageBlock';

function App() {
  const [showImage, setShowImage] = useState(false);

  const handleButtonClick = () => {
    setShowImage(true);
    setTimeout(() => setShowImage(false), 3000);
  };
  return (
    <div className="App">
      <Logo />
      <MainButton onClick={handleButtonClick} />
      <ImageBlock />
      // <Footer />
      <FloatingImage show={showImage} />
    </div>
  );
}

export default App;
