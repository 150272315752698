import React from 'react';
import './FloatingImage.css';


function FloatingImage({ show }) {
  return (
    show && (
      <div className="floating-image">
        <a href='https://mint.bitkitties.xyz/' target="_blank"><img src="https://s9.gifyu.com/images/SV3gq.gif" alt="Floating" /></a>
      </div>
    )
  );
}

export default FloatingImage;