import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="social-icons">
        {/* <a href="https://x.com/" target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
        <a href="https://discord.com/" target="_blank" rel="noopener noreferrer">
          Discord
        </a>
        <a href="https://magiceden.io/" target="_blank" rel="noopener noreferrer">
          MagicEden
        </a>
        <a href="https://solscan.io/" className="scan" target="_blank" rel="noopener noreferrer">
          Contract
        </a> */}
        {/* <a href="https://x.com/" target="_blank" rel="noopener noreferrer">
          <img src="путь_к_вашему_изображению_facebook" alt="Facebook" />
        </a>
        <a href="https://discord.com/" target="_blank" rel="noopener noreferrer">
          <img src="путь_к_вашему_изображению_twitter" alt="Twitter" />
        </a>
        <a href="https://magiceden.io/" target="_blank" rel="noopener noreferrer">
          <img src="путь_к_вашему_изображению_instagram" alt="Instagram" />
        </a>
        <a href="ссылка_на_ваш_instagram" target="_blank" rel="noopener noreferrer">
          <img src="путь_к_вашему_изображению_instagram" alt="Instagram" />
        </a> */}
      </div>
      <div className="copyright">
        &copy; {new Date().getFullYear()} BitKitties
        <p>All rights reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
