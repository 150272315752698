import React from 'react';
import './Logo.css';

function Logo() {
  return (
    <div className='logo-container'>
      <a href="https://bitkitties.xyz/"><img src="./logo.png" alt="Logo" className='logo' /></a>
    </div>
  );
}

export default Logo;
