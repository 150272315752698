import React from 'react';
import './ImageBlock.css';

function ImageBlock() {
    const images = [
        "https://s9.gifyu.com/images/SV3cO.gif",
    ];

    return (
        <div className="text-image-block">
           <div className="image-grid">
                {images.map((imageUrl, index) => (
                    <div key={index} className="image-item">
                        <img src={imageUrl} alt='main' />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ImageBlock;
