import React from 'react';
import './Menu.css';

function MainButton({ onClick }) {
  return (
    <nav className="menu-container">
      <ul className="menu">
        <li><a href="https://discord.gg/u9tUdFbcTQ/" className="menu-link" target='_blank'>Discord</a></li>
        <li><a href="https://twitter.com/bit_kitties/" className="menu-link" target='_blank'>Twitter</a></li>
        <li><a href="https://checker.bitkitties.xyz/" className="menu-link">Checker</a></li>
        <li><button className="button_mint" onClick={onClick}>Mint</button></li>
      </ul>
    </nav>

  );
}

export default MainButton;
